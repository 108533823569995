import Request from "@/utils/request";

export const getQuestionByRecordId = (params) => Request.get('/chick/examWeb/getQuestionByRecordId', { data: { ...params }, loading: false });

export const doAnswer = (params) => Request.post('/chick/examWeb/doAnswer', { data: { ...params }, loading: false });

export const doCollect = (params) => Request.get('/chick/examWeb/doCollect', { data: { ...params }, loading: false });

export const getCollect = (params) => Request.get('/chick/examWeb/getCollect', { data: { ...params }, loading: false });

export const getQuestionByQuestionId = (params) => Request.get('/chick/examWeb/getQuestionByQuestionId', { data: { ...params }, loading: false });

export const doExamError = (params) => Request.get('/chick/examWeb/doExamError', { data: { ...params }, loading: false });

export const doExamUserSetting = (params) => Request.post('/chick/examWeb/doExamUserSetting', { data: { ...params }, loading: false });

export const getExamUserSetting = (params) => Request.get('/chick/examWeb/getExamUserSetting', { data: { ...params }, loading: false });

export const getRealListByExamIdDetailId = (params) => Request.get('/chick/examWeb/getRealListByExamIdDetailId', { data: { ...params }, loading: false });

export const getChapter = (params) => Request.get('/chick/examWeb/getChapter', { data: { ...params }, loading: false });
export const getChapterYear = (params) => Request.post('/chick/examWeb/getChapterYear', { data: { ...params }, loading: false });
export const getChapterDo = (params) => Request.post('/chick/examWeb/getChapterDo', { data: { ...params }, loading: false });

