<template>
    <el-container class="container-div" v-loading="loading">
        <el-header>
            <div style="width: 100%;text-align: center;font-size: 28px;margin-top: 10px">
                {{ '收藏夹'}}
            </div>
        </el-header>
        <el-main>
            <el-row style="margin-bottom: 20px" >
                <el-col :span="2" :offset="3">
                    <el-button :type="question.collect ? 'primary':'danger'" round @click="doCollect">
                        <i :class="question.collect ?'el-icon-star-on':'el-icon-star-off'"></i>
                        {{ question.collect ? '已收藏' : '收藏'}}
                    </el-button>
                </el-col>
                <el-col :span="1">
                    <el-button icon="el-icon-s-tools" circle @click="setting"></el-button>
                </el-col>
                <el-col :span="2" v-if="examModel === '1'">
                    <el-button type="success" round @click="submit">
                        查看总题情况
                    </el-button>
                </el-col>
            </el-row>
            <el-row>
                <!--题目-->
                <div class="question-div">
                    <span v-html="question.name"></span><br>
                    <span v-html="question.content"></span>
                </div>
                <!--答题-->
                <div class="question-div">
                    <div v-for="(item, index) in question.weChatDoExamAnswerVOs" :key="item.id" style="margin-bottom: 10px;cursor:pointer" @click="(question.answered || examModel === '3')? false:doAnswer(index)">
                        <i style="font-size: 32px" class="iconfont icon-A_round" v-if="(index === 0  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 0 && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-B_round" v-if="(index === 1  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 1 && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-C_round" v-if="(index === 2  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 2 && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-D_round" v-if="(index === 3  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 3 && examModel === '3' && !item.yesOrNo))"></i>


                        <i style="font-size: 32px;color: #5397F8" class="el-icon-success" v-if="(examModel === '1' && item.id === question.answerId)||(item.yesOrNo && question.answered && examModel === '2') || (examModel === '3' && item.yesOrNo)"></i>
                        <i style="font-size: 32px;color: #F25E5E" class="el-icon-error" v-if="(item.id === question.answerId && !question.yesOrNo  && question.answered && examModel === '2')"></i>
                        <span v-html="item.content" style="vertical-align: top;line-height: 32px"></span>
                    </div>
                    <el-button v-if="data.type === 2 || data.type === 3" type="success" @click="zhuguanFlag = true"> 查看解析</el-button>
                </div>
<!--                <div class="question-div" style="text-align: center">-->
<!--                    <el-button type="primary" style="margin-right: 50%" @click="pre">上一题</el-button>-->
<!--                    <el-button type="primary" @click="next">下一题</el-button>-->
<!--                </div>-->
                <!--解析-->
                <div class="question-div" v-if="(question.answered && examModel !== '1') || examModel === '3' || (data.type !== 1 && zhuguanFlag)">
                    <div v-html="'<strong>解析：</strong><br>' + question.parse" style="margin: 10px 0 0 0"></div>
                </div>
            </el-row>
            <el-dialog
                title="系统设置"
                :visible.sync="dialogVisible"
                width="30%">
                <el-form ref="form" label-width="100px">
                    <el-form-item label="考试模式">
                        <el-radio-group v-model="examModelNew" :style="{'--colour': '#409EFF'}">
                            <el-radio :label="'1'" style="font-size: 16px">考试模式 <br>&nbsp;&nbsp;（不显示答案和解析）<br>&nbsp;&nbsp;（答题卡中可交卷、查看整体情况）
                            </el-radio>
                            <br>
                            <el-radio :label="'2'" style="margin-top: 20px">做题模式<br>&nbsp;&nbsp;（默认，答错时显示答案和解析）
                            </el-radio>
                            <br>
                            <el-radio :label="'3'" style="margin-top: 20px">背题模式<br>&nbsp;&nbsp;（直接显示答案和解析）</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="连续做对几次自动清除错题" label-width="100px">
                        <el-slider
                            v-model="wrongCount"
                            :min="2"
                            :max="10"
                            show-input>
                        </el-slider>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="doExamUserSetting">保存</el-button>
                </div>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import {
    getQuestionByRecordId,
    doAnswer,
    doCollect,
    getQuestionByQuestionId,
    doExamError,
    doExamUserSetting,
    getExamUserSetting
} from "@/api/frontStage/doExam";
import {Message} from "element-ui";

export default {
    name: "doExam",
    data() {
        return {
            questionId: this.$route.query.questionId,
            data: '',
            question: '',
            questionIndex: '',
            loading: false,
            examModel: '',
            examModelNew: '',
            wrongCount: '',
            zhuguanFlag: false,
            dialogVisible: false
        }
    },
    methods: {
        doCollect(){
            doCollect({questionId:this.question.id}).then(res => {
                this.data.weChatDoExamQuestionVOs[this.questionIndex].collect = (this.data.weChatDoExamQuestionVOs[this.questionIndex].collect? false : true);
            })
        },
        doAnswer(answerIndex) {
            console.log(answerIndex)
            // 当前题目
            var question = this.question;
            // 当前题目
            var questionIndex = this.questionIndex;
            // 全部答案
            var answers = question.weChatDoExamAnswerVOs
            // 当前答案
            var answer = answers[answerIndex];
            // 判断是否正确
            if (answer.yesOrNo) {
                // 正确
                if (!question.answered) {
                    // 未作答正确总数 + 1 已做答不变
                    this.data.rightCount = this.data.rightCount + 1;
                }
                // 问题 正确标识
                this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo = true;
            } else {
                // 错误
                if (!question.answered) {
                    // 未作答错误总数 + 1 已做答不变
                    this.data.wrongCount = this.data.wrongCount + 1;
                }
                // 问题 正确标识
                this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo = false;
            }
            // 题目已做
            this.data.weChatDoExamQuestionVOs[questionIndex].answered = true;
            // 答案id
            this.data.weChatDoExamQuestionVOs[questionIndex].answerId = answer.id;
            // 不请求
            const data = {
                'questionId': question.id,
                'recordId': this.recordId,
                'answerId': answer.id,
                'isRight': this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo ? '0' : '1',
                'type':'2'
            }
            doAnswer(data).then(res => {
                console.log(res)
            })
        },
        getRightAnswer() {
            for (var i = 0; i < this.question.weChatDoExamAnswerVOs.length; i++) {
                if (this.question.weChatDoExamAnswerVOs[i].yesOrNo) {
                    return this.question.weChatDoExamAnswerVOs[i].content;
                }
            }
        },
        getQuestionByQuestionId() {
            this.loading = true;
            // 检查参数正确性
            if (this.questionId == null || this.questionId === '' || this.questionId === undefined) {
                this.goIndex();
            }
            // 查询题目
            getQuestionByQuestionId({'questionId': this.questionId}).then(res => {
                if (res.code === 1) {
                    this.goIndex();
                }
                this.data = res.data;
                this.question = res.data.weChatDoExamQuestionVOs[0];
                this.questionIndex = 0;
                this.loading = false;
            })
        },
        switchQuestion(index) {
            this.question = this.data.weChatDoExamQuestionVOs[index];
            this.questionIndex = index;
        },
        goIndex() {
            this.$router.push({
                path: '/examDetail',
                query: {examId: "0282b9e4-0c58-4dfa-b3f1-ddd29f3fc1a7", examType: 'EXAM_01'}
            });
        },
        async getExamUserSetting() {
            await getExamUserSetting().then(res => {
                this.examModel = res.data.examModel;
                this.wrongCount = res.data.wrongCount;
            })
        },
        setting() {
            this.dialogVisible = true;
            this.examModelNew = this.examModel;
        },
        doExamUserSetting() {
            const data = {
                examModel: this.examModelNew,
                wrongCount: this.wrongCount
            }
            doExamUserSetting(data).then(res => {
                this.examModel = this.examModelNew;
                if (this.examModel === '3') {
                    this.answerId = this.getRightAnswerId();
                } else {
                    this.answerId = this.data.weChatDoExamQuestionVOs[this.questionIndex].answerId;
                    this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
                }
                this.dialogVisible = false;
            })
        },
        getRightAnswerId() {
            for (var i = 0; i < this.question.weChatDoExamAnswerVOs.length; i++) {
                if (this.question.weChatDoExamAnswerVOs[i].yesOrNo) {
                    return this.question.weChatDoExamAnswerVOs[i].id;
                }
            }
        },
        submit() {
            // 检查是不是都做完了
            var that = this;
            var allCount = 0;
            var doCount = 0;
            var rightCount = 0;
            var icon = 'error'
            allCount = that.data.weChatDoExamQuestionVOs.length;
            for (var i = 0; i < that.data.weChatDoExamQuestionVOs.length; i++) {
                if (that.data.weChatDoExamQuestionVOs[i].answered) {
                    doCount++;
                }
                if (that.data.weChatDoExamQuestionVOs[i].yesOrNo) {
                    rightCount++;
                }
            }
            var msg = '';
            if (rightCount / allCount > 0.6) {
                msg = '恭喜你，闯关成功'
                icon = 'success'
            } else {
                msg = '很遗憾，闯关失败'
            }
            this.$confirm('总题数：' + allCount + "<br>作答数：" + doCount + "<br>正确数：" + rightCount + '<br>正确率（总/正确）：' + parseFloat(rightCount / allCount * 100).toFixed(2) + "%", msg, {
                confirmButtonText: '查看答案',
                cancelButtonText: '继续做题',
                type: icon,
                dangerouslyUseHTMLString: true
            }).then(() => {
                // 查看答案
                this.examModel = '2';
            }).catch(() => {
                // 继续做题
            });
        },
        next(){
            if (this.questionIndex >= this.data.allCount - 1){
                Message.success({message: "已是最后一题"});
            } else {
                this.questionIndex ++;
                this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
            }
            console.log(this.data)
            console.log(this.question)
            console.log(this.questionIndex)
        },
        pre(){
            if (this.questionIndex <= 0){
                Message.success({message: "已是第一道题"});
            } else {
                this.questionIndex --;
                this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
            }
        }
    },
    filters: {},
    created() {
        this.getQuestionByQuestionId();
        this.getExamUserSetting();
    }
}
</script>

<style scoped>
.container-div {
    margin: 0;
    padding: 0;
    min-height: 1200px;
}

.question-div {
    width: 80%;
    margin: 10px auto 30px auto;
    font-size: 20px
}
</style>
